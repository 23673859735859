import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, NumberInput, TextArea} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageCommissioningPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('savr_cost_coverage_tab');
    const {serviceAssignment, onChangeHandler, agreedPrice} = props;
    const {assignmentText, serviceCase} = props;
    const damageDescriptionTextRows = serviceCase.damage && serviceCase.damage.description
        ? serviceCase.damage.description.split('\n') : [];

    return (
        <Panel
            title={translateTab('panel_title.commissioning')}
            qaIdent="savr-cost-coverage-commissioning"
        >
            <div className={cx(['global!ace-u-margin--bottom-24'])}>
                <DataRow
                    label={translateTab('data_row_label.assignment_text')}
                    qaIdent="sa-assignment-text"
                >
                    <TextArea
                        className={cx('global!ace-u-flex--grow-1')}
                        isResizable={false}
                        value={assignmentText}
                        onChange={value => onChangeHandler('assignmentText', value)}
                    />
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.damage_description')}
                >
                    <div>
                        {damageDescriptionTextRows.map(stringRow => (
                            <p key={stringRow}>
                                {stringRow}
                            </p>
                        ))}
                        <br />
                        {serviceCase.damageDescription}
                    </div>
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.agreed_price')}
                    className={cx('ace-c-data-row--condensed')}
                    qaIdent="sa-agreed-price"
                >
                    <NumberInput
                        className={cx('global!ace-u-flex--grow-1')}
                        value={agreedPrice}
                        onChange={value => onChangeHandler('agreedPrice', value)}
                        min={0}
                    />
                </DataRow>
            </div>
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.pickup_location')}
                        qaIdent="sa-pickup-location"
                    >
                        {serviceAssignment.pickupLocation ? (
                            <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}>
                                <div>{serviceAssignment.pickupLocation.locationName}</div>
                                <div>{serviceAssignment.pickupLocation.formattedAddress}</div>
                                <div>{serviceAssignment.pickupLocationPhoneNo || ''}</div>
                            </div>
                        ) : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.pickup_location_fees')}
                        qaIdent="sa-pickup-location-fees"
                    >
                        <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}>
                            <div>{translateTab('text.stall_fee_per_day')}&nbsp;{serviceAssignment.stallFee?.toLocaleString(activeLocale) || '-'}</div>
                            <div>{translateTab('text.other_fees_in_euro')}&nbsp;{serviceAssignment.otherFees?.toLocaleString(activeLocale) || '-'}</div>
                        </div>
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.destination')}
                        qaIdent="sa-destination"
                    >
                        {serviceAssignment.destination ? (
                            <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}>
                                <div>{serviceAssignment.destination.locationName}</div>
                                <div>{serviceAssignment.destination.formattedAddress}</div>
                                <div>{serviceAssignment.destinationPhoneNo || ''}</div>
                            </div>
                        ) : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.distance')}
                        qaIdent="sa-route-distance"
                    >
                        {serviceAssignment.routeDistance || '-'}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CostCoverageCommissioningPanel.propTypes = {
    serviceCase: PropTypes.object,
    onChangeHandler: PropTypes.func.isRequired,
    serviceAssignment: PropTypes.object,
    // additionalNotes: PropTypes.string,
    agreedPrice: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    assignmentText: PropTypes.string,
    damageDescription: PropTypes.string,
};

CostCoverageCommissioningPanel.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    // additionalNotes: '',
    agreedPrice: '',
    assignmentText: '',
    damageDescription: '',
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageCommissioningPanel));
