import {put, select, take, fork, call, all} from 'redux-saga/effects';
import {efServiceAssignmentTypes, ACEPartner, TemporaryRestriction, efServiceCaseTypes, apmContractPartnerContractStatusTypes, apmACEPartnerServiceTypes, efDamageNodeTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as sapActionTypes from '../sapActionTypes';
import isTemporaryRestrictionActive from '../../contract-partners/isTemporaryRestrictionActive';
import * as serviceCaseHelpers from '../../service-cases/sagas/serviceCaseHelpers';
import serviceAssignmentPartnerDisabledStateStatuses from '../../service-assignments/serviceAssignmentPartnerDisabledStateStatuses';
import isACEPartnerFromDACH from '../../service-assignments/isACEPartnerFromDACH';
import config from '../../config';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';

const createDamageDescriptionString = (damageNodeSnapshots, damageNodes, translate, translateToEnglish) => {
    let snapshotIds = [...damageNodeSnapshots];
    const description = [];
    damageNodeSnapshots.forEach(nodeId => {
        if (!snapshotIds.includes(nodeId)) return;
        const node = damageNodes[nodeId];
        if (!node) return;
        if (node.nodeType === efDamageNodeTypes.ANSWER) {
            translateToEnglish ? description.push(translate(node.name, {}, 'en-US'))
                : description.push(translate(node.name));
            description.push('\n');
            return;
        }

        if (node.nodeType === efDamageNodeTypes.DECISION) {
            const childrenInSnapshot = node.childNodeIds.filter(childId => damageNodeSnapshots.includes(childId));
            if (childrenInSnapshot.length) {
                translateToEnglish ? description.push(translate(node.name, {}, 'en-US'), ' ')
                    : description.push(translate(node.name), ' ');
                childrenInSnapshot.forEach((childrenId, idx) => {
                    translateToEnglish ? description.push(translate(damageNodes[childrenId].name, {}, 'en-US'))
                        : description.push(translate(damageNodes[childrenId].name));
                    if (idx !== childrenInSnapshot.length - 1) {
                        description.push('***');
                    }
                    snapshotIds = snapshotIds.filter(nodeId => nodeId !== childrenId);
                });
                description.push('\n');
            }
        }
    });
    return description.toString().replaceAll(',', '').replaceAll('***', ', ');
};

/**
 * Returns if Contract Partner has service warning (from DTO)
 * @param contractPartnerDTO
 * @returns {boolean}
 */
const hasContractPartnerDTOServicesWarning = contractPartnerDTO => !!contractPartnerDTO?.isOpeningSoon;

const filterSAPMapContractPartnersByLocation = function* filterSAPMapContractPartnersByLocation(
    serviceCaseId,
    serviceAssignmentLineNo,
    isVBASearch = false,
) {
    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const {contractPartners} = yield select(state => state.contractPartners);
    const {serviceCases} = yield select(state => state.serviceCases);

    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];
    const serviceCase = serviceCases[serviceCaseId];

    const {pickupLocation, destination, listOfPreviousPartners = []} = serviceAssignment;
    if (!serviceAssignment || !pickupLocation || !destination) return;

    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const i18nService = serviceManager.loadService('i18nService');
    const {translate} = i18nService;
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-pickup');
    if (!arcGISMap) return;

    const sapContractPartnerLocationsLayer = yield call(arcGISMap.getLayer, 'sap-contract-partner-locations');
    const sapContractPartnerServiceAreasLayer = yield call(arcGISMap.getLayer, 'sap-contract-partner-service-areas');
    const sapContractPartnerRoutesLayer = yield call(arcGISMap.getLayer, 'sap-contract-partner-routes');

    if (!sapContractPartnerLocationsLayer
        || !sapContractPartnerServiceAreasLayer
        || !sapContractPartnerRoutesLayer) return;

    const prevContractPartnerRecommendations = serviceAssignment?.contractPartnerRecommendations || [];
    const prevRecommendedCPId = serviceAssignment.recommendedContractPartnerId;
    const isPrevSelectedCPRecommended = serviceAssignment?.acePartner?.id
        && contractPartners
        /* eslint-disable-next-line max-len */
        && contractPartners[serviceAssignment.acePartner.id]?.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE
        && serviceAssignment?.recommendedContractPartnerId
        && serviceAssignment.acePartner.id === serviceAssignment.recommendedContractPartnerId;

    yield* serviceCaseHelpers.setPersistencePending(serviceCaseId);

    yield put({
        type: sapActionTypes.SET_SAP_CONTRACT_PARTNER_RECOMMENDATIONS,
        payload: {serviceAssignmentId, contractPartnerRecommendationDTOs: []},
    });

    // filter Contract Partner base features (points) by matching available services
    const matchedServicesContractPartnerDTOs = Object.values(contractPartners).map(contractPartner => {
        // only ACTIVE contract partners can be selected - APM should be a source of truth, see ACEMS-997
        /* eslint-disable max-len */
        if (!isVBASearch && contractPartner?.contractStatus !== apmContractPartnerContractStatusTypes.ACTIVE) return null;
        // if it's a VBA search, only VBA partners can be selected
        if (isVBASearch && contractPartner?.contractStatus !== apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY) return null;
        /* eslint-enable max-len */
        const contractPartnerDTO = {
            'contractPartnerId': contractPartner.id,
        };

        // if the contract partner does not support pickup as a service, exclude it
        if (!contractPartner?.services?.find(service => {
            return service.serviceType === apmACEPartnerServiceTypes.PICKUP;
        })) {
            return null;
        }

        // check if contract partner is working
        const {isOpen, isOpeningSoon} = contractPartner.isContractPartnerOpen();
        if (!isOpen && !isOpeningSoon) return null;
        if (isOpeningSoon) {
            contractPartnerDTO['isOpeningSoon'] = true;
        }

        return contractPartnerDTO;
    }).filter(contractPartnerDTO => !!contractPartnerDTO);

    yield put({
        type: sapActionTypes.SET_SAP_CP_RECOMMENDATIONS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.PENDING},
    });

    // filter Contract Partner service area features (polygons) by referential point
    yield fork(
        fetchRequest,
        sapActionTypes.FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST,
        sapContractPartnerServiceAreasLayer.filterPolygonFeaturesByReferentialPoint,
        {
            referentialPoint: pickupLocation,
            where: `contractStatus = '${isVBASearch ? apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY : apmContractPartnerContractStatusTypes.ACTIVE}' AND contractPa IN (${matchedServicesContractPartnerDTOs
                .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                .join(', ')})`,
        },
    );

    const filterCPServiceAreasByReferentialPointResponseAction = yield take([
        sapActionTypes.FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_FAILED,
        sapActionTypes.FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SUCCEEDED,
    ]);

    let contractPartnerServiceAreasForReferentialPointDTOs = [];
    if (!filterCPServiceAreasByReferentialPointResponseAction.error) {
        const {response} = filterCPServiceAreasByReferentialPointResponseAction.payload;
        contractPartnerServiceAreasForReferentialPointDTOs = response.featureDTOs || [];
    }

    // filter Contract Partner base features (points) by distance
    yield fork(
        fetchRequest,
        sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST,
        sapContractPartnerLocationsLayer.filterPointFeaturesByDistance,
        {
            distance: 100,
            referentialPoint: pickupLocation,
            returnRoutes: true,
            travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
            returnLocationFromAttribute: 'address',
            featureCount: 10,
            where: `contractStatus = '${isVBASearch ? apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY : apmContractPartnerContractStatusTypes.ACTIVE}' AND contractPa IN (${matchedServicesContractPartnerDTOs
                .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                .join(', ')})`,
        },
    );

    const filterCPDistanceRecommendationsResponseAction = yield take([
        sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED,
        sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED,
    ]);

    let contractPartnerDistanceRecommendationDTOs = [];
    if (!filterCPDistanceRecommendationsResponseAction.error) {
        const {response} = filterCPDistanceRecommendationsResponseAction.payload;
        contractPartnerDistanceRecommendationDTOs = response.featureDTOs;
    }

    // join contractPartnerRecommendation results with matchedServicesContractPartnerDTOs
    contractPartnerDistanceRecommendationDTOs = contractPartnerDistanceRecommendationDTOs.map(contractPartnerDTO => {
        const matchedContractPartnerDTO = matchedServicesContractPartnerDTOs
            .find(contractPartner => contractPartner['contractPartnerId'] === contractPartnerDTO['contractPartnerId']);
        return {
            ...contractPartnerDTO,
            ...(matchedContractPartnerDTO || {}),
        };
    });

    // filter Contract Partner base features (points) by matching service areas
    let contractPartnerServiceAreaRecommendationDTOs = [];
    if (contractPartnerServiceAreasForReferentialPointDTOs.length > 0) {
        yield fork(
            fetchRequest,
            sapActionTypes.FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST,
            sapContractPartnerLocationsLayer.filterFeaturesByAttribute,
            {
                where: `contractPa IN (${contractPartnerServiceAreasForReferentialPointDTOs
                    .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                    .join(', ')})`,
                referentialPoint: pickupLocation,
                returnRoutes: true,
                travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
                returnLocationFromAttribute: 'address',
                keepPreviousResults: true,
            },
        );

        const filterCPServiceAreaRecommendationsResponseAction = yield take([
            sapActionTypes.FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_FAILED,
            sapActionTypes.FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SUCCEEDED,
        ]);

        if (!filterCPServiceAreaRecommendationsResponseAction.error) {
            const {response} = filterCPServiceAreaRecommendationsResponseAction.payload;
            contractPartnerServiceAreaRecommendationDTOs = response.featureDTOs;
        }
    }

    // join contract partner recommendation results
    const contractPartnerDistanceRecommendationIds = contractPartnerDistanceRecommendationDTOs
        .map(contractPartnerDTO => contractPartnerDTO['contractPartnerId']);
    let contractPartnerRecommendationDTOs = [
        ...contractPartnerServiceAreaRecommendationDTOs.filter(contractPartnerDTO => {
            return !contractPartnerDistanceRecommendationIds.includes(contractPartnerDTO['contractPartnerId']);
        }),
        ...contractPartnerDistanceRecommendationDTOs,
    ];

    // if current contract partner is not in recommended set, render it in layers
    // the exception is when isVBASearch=true, then do not display previously selected CP
    if (serviceAssignment.acePartner
        && !serviceAssignment.acePartner.isManuallyAdded
        /* eslint-disable max-len */
        && !(isVBASearch && contractPartners && contractPartners[serviceAssignment.acePartner?.id]?.contractStatus !== apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY)
        && !(!isVBASearch && contractPartners && contractPartners[serviceAssignment.acePartner?.id]?.contractStatus === apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY)
        /* eslint-enable max-len */
        && !contractPartnerRecommendationDTOs.find(contractPartnerDTO => {
            return contractPartnerDTO['contractPartnerId'] === serviceAssignment.acePartner.id;
        })) {
        yield fork(
            fetchRequest,
            sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST,
            sapContractPartnerLocationsLayer.filterFeaturesByAttribute,
            {
                where: `contractPa = '${serviceAssignment.acePartner.id}'`,
                referentialPoint: pickupLocation,
                returnRoutes: true,
                travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
                returnLocationFromAttribute: 'address',
                // keep results from FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST
                keepPreviousResults: true,
            },
        );

        const filterPointFeaturesResponseAction = yield take([
            sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED,
            sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED,
        ]);

        if (!filterPointFeaturesResponseAction.error) {
            const {response} = filterPointFeaturesResponseAction.payload;
            const {featureDTOs: currentContractPartnerRecommendationDTOs = []} = response;
            // check if current contract partner supports PICKUP as a service
            const updatedCurrentContractPartnerRecommendationDTOs = currentContractPartnerRecommendationDTOs
                .filter(contractPartnerDTO => {
                    const contractPartner = contractPartners[contractPartnerDTO['contractPartnerId']];
                    if (!contractPartner) return false;

                    // if the contract partner does not support pickup as a service, display warning
                    if (!contractPartner?.services?.find(service => {
                        return service.serviceType === apmACEPartnerServiceTypes.PICKUP;
                    })) {
                        contractPartnerDTO['isPickupServiceSupported'] = true;
                    }

                    return true;
                });
            contractPartnerRecommendationDTOs.push(...updatedCurrentContractPartnerRecommendationDTOs);
        }
    }

    // sort contract partner recommendation results by distance from damage location
    contractPartnerRecommendationDTOs = contractPartnerRecommendationDTOs
        .sort((locationA, locationB) => {
            if (!locationA['routeToDamageLocation']
                || !locationB['routeToDamageLocation']) return 0;
            const {routeToDamageLocation: locationARoute} = locationA;
            const {routeToDamageLocation: locationBRoute} = locationB;
            return (locationARoute.totalKilometers - locationBRoute.totalKilometers);
        });

    // filter Contract Partner service area features (polygons) by distance recommendations
    let contractPartnerServiceAreasForDistanceDTOs = [];
    if (contractPartnerDistanceRecommendationDTOs.length > 0) {
        yield fork(
            fetchRequest,
            sapActionTypes.FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST,
            sapContractPartnerServiceAreasLayer.filterFeaturesByAttribute,
            {
                where: `contractPa IN (${contractPartnerDistanceRecommendationDTOs
                    .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                    .concat(serviceAssignment?.acePartner?.id ? [`'${serviceAssignment.acePartner.id}'`] : [])
                    .join(', ')})`,
                referentialPoint: pickupLocation,
                // keep results from FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST
                keepPreviousResults: true,
            },
        );

        const filterContractPartnerServiceAreasResponseAction = yield take([
            sapActionTypes.FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_FAILED,
            sapActionTypes.FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SUCCEEDED,
        ]);

        if (!filterContractPartnerServiceAreasResponseAction.error) {
            const {response} = filterContractPartnerServiceAreasResponseAction.payload;
            contractPartnerServiceAreasForDistanceDTOs = response.featureDTOs;
        }
    }

    // join contract partner service area results
    const contractPartnerServiceAreasForDistanceIds = contractPartnerServiceAreasForDistanceDTOs
        .map(contractPartnerDTO => contractPartnerDTO['contractPartnerId']);
    const contractPartnerServiceAreaDTOs = [
        ...contractPartnerServiceAreasForReferentialPointDTOs.filter(contractPartnerDTO => {
            return !contractPartnerServiceAreasForDistanceIds.includes(contractPartnerDTO['contractPartnerId']);
        }),
        ...contractPartnerServiceAreasForDistanceDTOs,
    ];

    // flag contract partner recommendations that have service area
    if (contractPartnerRecommendationDTOs.length > 0 && contractPartnerServiceAreaDTOs.length > 0) {
        const contractPartnerServiceAreaIds = contractPartnerServiceAreaDTOs
            .filter(contractPartnerServiceAreaDTO => contractPartnerServiceAreaDTO['geometry'])
            .map(contractPartnerServiceAreaDTO => contractPartnerServiceAreaDTO['contractPartnerId']);

        // find CPs with service area
        contractPartnerRecommendationDTOs = contractPartnerRecommendationDTOs
            .map(contractPartnerRecommendationDTO => {
                return {
                    ...contractPartnerRecommendationDTO,
                    hasServiceArea: contractPartnerServiceAreaIds.includes(
                        contractPartnerRecommendationDTO['contractPartnerId'],
                    ),
                };
            });
    }

    let recommendedACEPartners = contractPartners;
    // fetch temporary restrictions for recommended contract partners
    // fetch the most recent data from APM
    if (contractPartnerRecommendationDTOs.length > 0) {
        const restrictionsMap = yield call(partnerManagementService.getACEPartnersRestrictions, {
            acePartnerIds: contractPartnerRecommendationDTOs
                .map(contractPartnerRecommendationDTO => contractPartnerRecommendationDTO['contractPartnerId'])
                .filter(Boolean),
        });
        contractPartnerRecommendationDTOs.forEach(contractPartnerRecommendationDTO => {
            if (restrictionsMap && restrictionsMap[contractPartnerRecommendationDTO['contractPartnerId']]) {
                const temporaryRestrictions = new Map();
                restrictionsMap[contractPartnerRecommendationDTO['contractPartnerId']]
                    .forEach(restriction => {
                        const temporaryRestriction = new TemporaryRestriction().fromDTO(restriction);
                        if (isTemporaryRestrictionActive(temporaryRestriction)) {
                            temporaryRestrictions.set(temporaryRestriction.id, temporaryRestriction);
                        }
                    });
                contractPartnerRecommendationDTO.temporaryRestrictions = temporaryRestrictions;
            }
        });

        const {acePartnerDTOs} = yield call(partnerManagementService.getACEPartnersById, {
            acePartnerIds: contractPartnerRecommendationDTOs
                .map(contractPartnerRecommendationDTO => contractPartnerRecommendationDTO['contractPartnerId'])
                .filter(Boolean),
        });
        if (acePartnerDTOs.length > 0) {
            recommendedACEPartners = {};
            acePartnerDTOs.forEach(acePartnerDTO => {
                recommendedACEPartners[acePartnerDTO.id] = new ACEPartner().fromDTO(acePartnerDTO);
            });
            yield put({
                type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                payload: {contractPartnerDTOs: acePartnerDTOs},
            });
        }
    }

    // patch contractPartnerRecommendationDTOs (from ArcGIS) with APM data
    contractPartnerRecommendationDTOs = contractPartnerRecommendationDTOs.map(contractPartnerDTO => {
        const apmContractPartner = recommendedACEPartners[contractPartnerDTO['contractPartnerId']];
        if (!apmContractPartner) return contractPartnerDTO;

        return apmContractPartner.patchArcGISDataIntoDTO(contractPartnerDTO);
    });

    // find service areas containing pickup location (bestMatchingContractPartnerIds)
    let bestMatchingContractPartnerIds = [];
    if (contractPartnerServiceAreaDTOs.length > 0) {
        const bestMatchingContractPartnerDTOs = contractPartnerServiceAreaDTOs
            .filter(contractPartnerServiceAreaDTO => contractPartnerServiceAreaDTO['containsDamageLocation'])
            .map(contractPartnerServiceAreaDTO => contractPartnerRecommendationDTOs.find(contractPartnerDTO => {
                return contractPartnerDTO['contractPartnerId'] === contractPartnerServiceAreaDTO['contractPartnerId'];
            }))
            // fix https://computerrock.atlassian.net/browse/ACEECS-5885
            // invalid CP -> only polygon exists, a point does not exist on the layer (location = null)
            // find method returns undefined => bestMatchingContractPartnerDTOs = [undefined]
            .filter(contractPartnerDTO => !!contractPartnerDTO);

        // filter-out any area best matching CP if it has service warning, so the next matching step can:
        // prefer CPs by distance: https://computerrock.atlassian.net/browse/ACEECS-4825
        bestMatchingContractPartnerIds = bestMatchingContractPartnerDTOs
            .filter(contractPartnerDTO => !hasContractPartnerDTOServicesWarning(contractPartnerDTO))
            .map(contractPartnerDTO => contractPartnerDTO['contractPartnerId']);
    }

    // find candidate contract partner based on distance from damage location
    let nearestRoute;
    let recommendedContractPartnerDTO;
    (bestMatchingContractPartnerIds.length === 0
        ? contractPartnerRecommendationDTOs
        : contractPartnerRecommendationDTOs.filter(contractPartnerDTO => {
            return bestMatchingContractPartnerIds.includes(contractPartnerDTO['contractPartnerId']);
        }))
        .forEach(contractPartnerDTO => {
            const routeToDamageLocationDTO = contractPartnerDTO['routeToDamageLocation'];
            if (!routeToDamageLocationDTO) return;
            if ((routeToDamageLocationDTO['totalKilometers'] < nearestRoute || !recommendedContractPartnerDTO)
                // filter-out any CP if it has service warning
                // https://computerrock.atlassian.net/browse/ACEECS-4825
                && !hasContractPartnerDTOServicesWarning(contractPartnerDTO)) {
                recommendedContractPartnerDTO = contractPartnerDTO;
                nearestRoute = routeToDamageLocationDTO['totalKilometers'];
            }
        });

    // calculate routes from CP to towing destination
    // route calculation: pickup location -> towing destination -> CP base
    // CP search: find nearest CPs around pickup location
    if (contractPartnerRecommendationDTOs.length > 0) {
        const routes = yield call(
            arcGISRESTService.getBulkRoutes,
            contractPartnerRecommendationDTOs.map(contractPartnerDTO => {
                if (!contractPartnerDTO.location?.latitude || !contractPartnerDTO.location?.longitude) return null;
                return {
                    startingPoint: {
                        longitude: destination.longitude,
                        latitude: destination.latitude,
                    },
                    destination: {
                        longitude: contractPartnerDTO.location.longitude,
                        latitude: contractPartnerDTO.location.latitude,
                    },
                };
            }),
            arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
        );
        contractPartnerRecommendationDTOs.forEach((contractPartnerDTO, index) => {
            contractPartnerDTO['routeToDamageLocation'] = routes[index];
        });
    }

    // render routes for recommended contract partners
    yield call(sapContractPartnerRoutesLayer.setFeatures, {
        features: (contractPartnerRecommendationDTOs || []).map(contractPartnerDTO => {
            const routeToPickupLocation = contractPartnerDTO['routeToDamageLocation'];
            if (!routeToPickupLocation) return null;
            return {
                attributes: {
                    'FID': contractPartnerDTO['featureId'],
                    'contractPa': contractPartnerDTO['contractPartnerId'],
                    'Name': contractPartnerDTO['contractPartnerName'],
                },
                geometry: routeToPickupLocation.geometry,
            };
        }).filter(Boolean),
    });

    // select features for current contract partner or the recommended one
    const selectedContractPartner = serviceAssignment.acePartner?.isManuallyAdded
        ? serviceAssignment.acePartner
        : (serviceAssignment.acePartner?.id
            ? contractPartners[serviceAssignment.acePartner.id] || null : null);
    const previousPartners = serviceAssignment.listOfPreviousPartners || [];
    /* eslint-disable max-len */
    const selectedContractPartnerId = selectedContractPartner?.isManuallyAdded
        ? null
        : (selectedContractPartner
            && ((isVBASearch && selectedContractPartner.contractStatus === apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY)
                || (!isVBASearch && selectedContractPartner.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE))
            ? selectedContractPartner.id
            : (recommendedContractPartnerDTO
                && !(previousPartners?.length && previousPartners.includes(recommendedContractPartnerDTO['contractPartnerId']))
                ? recommendedContractPartnerDTO['contractPartnerId'] : null));
    /* eslint-enable max-len */
    if (selectedContractPartnerId) {
        yield all([
            call(sapContractPartnerLocationsLayer.selectFeatureByAttribute, {
                where: `contractPa = '${selectedContractPartnerId}'`,
            }),
            call(sapContractPartnerServiceAreasLayer.selectFeatureByAttribute, {
                where: `contractPa = '${selectedContractPartnerId}'`,
            }),
            call(sapContractPartnerRoutesLayer.selectFeatureByAttribute, {
                where: `contractPa = '${selectedContractPartnerId}'`,
            }),
        ]);
    }

    // save recommended contract partner as selected if no contract partner was previously set
    // save recommended contract partner as selected if VBA search is active, and currently selected
    // contract partner is not VBA partner
    if (recommendedContractPartnerDTO
        && !(previousPartners?.length && previousPartners.includes(recommendedContractPartnerDTO['contractPartnerId']))
        && (!serviceAssignment.acePartner || (
            selectedContractPartnerId && selectedContractPartnerId !== selectedContractPartner?.id
        ))
    ) {
        const recommendedContractPartner = new ACEPartner()
            .fromDTO(recommendedACEPartners[recommendedContractPartnerDTO.id] || null);
        const {damageNodeSnapshots} = serviceCase;

        if (damageNodeSnapshots.length > 0) {
            const isDACH = isACEPartnerFromDACH(recommendedContractPartner);
            const damageNodes = yield select(state => state.serviceCases.serviceTypeDamageNodes);
            const description = createDamageDescriptionString(damageNodeSnapshots, damageNodes, translate, !isDACH);

            yield* updateServiceCase({
                caller: 'LOAD_SAP_CONTRACT_PARTNER_RECOMMENDATIONS',
                caseType: efServiceCaseTypes.VEHICLE,
                serviceCaseId: serviceAssignment.serviceCaseId,
                serviceCaseData: {
                    damage: {
                        description: description,
                    },
                },
            });
        }

        yield* updateServiceAssignment({
            caller: 'LOAD_SAP_CONTRACT_PARTNER_RECOMMENDATIONS',
            assignmentType: efServiceAssignmentTypes.PICKUP,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                acePartner: {
                    ...recommendedContractPartner,
                    ...(recommendedContractPartner.businessContactDetails
                        ? {
                            businessContactDetails: {
                                ...recommendedContractPartner.businessContactDetails,
                                email: recommendedContractPartner?.assignmentEmail || '',
                                faxNo: recommendedContractPartner?.assignmentFaxNo || '',
                                phoneNo: recommendedContractPartner?.emergencyContacts?.find(contact => contact.is24h7Emergency)?.phoneNo || '',
                            },
                            contactDetails: null,
                        } : {}
                    ),
                    rank: config.RECOMMENDED_CONTRACT_PARTNER_RANK, // recommended CP has always a rank of 1
                },
                assignmentText: null,
            },
        });

        // set persistence state back to PENDING
        yield* serviceCaseHelpers.setPersistencePending(serviceCaseId);
    }

    yield put({
        type: sapActionTypes.SET_SAP_CONTRACT_PARTNER_RECOMMENDATIONS,
        payload: {
            serviceAssignmentId,
            contractPartnerRecommendationDTOs,
            recommendedContractPartnerId: recommendedContractPartnerDTO
                ? recommendedContractPartnerDTO['contractPartnerId'] : null,
        },
    });

    yield put({
        type: sapActionTypes.SET_SAP_CP_RECOMMENDATIONS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.READY},
    });

    yield all([
        call(sapContractPartnerLocationsLayer.disableFeatureByAttribute, {
            where: `contractPa IN (${(listOfPreviousPartners || [])
                .map(contractPartnerId => `'${contractPartnerId}'`)
                .join(', ')})`,
        }),
        call(sapContractPartnerRoutesLayer.disableFeatureByAttribute, {
            where: `contractPa IN (${(listOfPreviousPartners || [])
                .map(contractPartnerId => `'${contractPartnerId}'`)
                .join(', ')})`,
        }),
        call(sapContractPartnerServiceAreasLayer.disableFeatureByAttribute, {
            where: `contractPa IN (${(listOfPreviousPartners || [])
                .map(contractPartnerId => `'${contractPartnerId}'`)
                .join(', ')})`,
        }),
    ]);

    sapContractPartnerLocationsLayer.show();
    sapContractPartnerServiceAreasLayer.show();
    sapContractPartnerRoutesLayer.show();

    const isPartnerChangingDisabled = serviceCase?.caseType === efServiceCaseTypes.VEHICLE
        && serviceAssignmentPartnerDisabledStateStatuses.includes(serviceAssignment?.assignmentStatus);
    // if ace partner is entered manually, disable locations layer
    if (serviceAssignment?.acePartner?.isManuallyAdded || isPartnerChangingDisabled) {
        sapContractPartnerLocationsLayer.toggleLayerDisabled(true);
    }

    yield* serviceCaseHelpers.setPersistenceReady(serviceCaseId);

    // if it's a VBA search and there is a previously selected ACTIVE recommended CP
    // and in the list of the VBA partners there is a recommended one
    // then open a modal for creating a QM note
    if (isVBASearch && isPrevSelectedCPRecommended && recommendedContractPartnerDTO) {
        yield put({
            type: sapActionTypes.INITIATE_SAP_VBA_PARTNER_QM_FEEDBACK_DRAFT_CREATION,
            payload: {
                serviceCaseId,
                serviceAssignmentLineNo,
                recommendedCPId: prevRecommendedCPId,
                contractPartnerRecommendations: prevContractPartnerRecommendations,
            },
        });
    }
};

export default filterSAPMapContractPartnersByLocation;
