import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, Checkbox, Pill, NumberInputField} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import getPrice from '../../utils/getPrice';

const CostCoverageCostOverviewPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('saa_cost_coverage_tab');
    const {serviceCase, hotelCost, costConfirmedByMember, onChangeHandler, serviceAssignment, hasCostError} = props;
    const areDatesSelected = serviceAssignment.stayStartDate && serviceAssignment.stayEndDate;

    const isFullyCovered = hotelCost - serviceAssignment.totalCoverage <= 0;

    return (
        <Panel
            title={(
                <div>
                    {translateTab('panel_title.cost_overview')}
                    <p className={cx('global!ace-u-typography--variant-body')}>
                        <span
                            className={cx({
                                'global!ace-u-typography--color-highlighted': !areDatesSelected,
                            })}
                        >
                            {areDatesSelected
                                ? translateTab('panel_title.number_of_nights', {numberOfNights: serviceAssignment.numberOfNights})
                                : translateTab('panel_title.no_dates_selected')}
                        </span>
                        {`, ${translateTab('panel_title.number_of_persons', {numberOfPersons: serviceAssignment.numberOfAdults + serviceAssignment.numberOfKids})}`}
                    </p>
                </div>
            )}
            qaIdent="saa-cost-coverage-cost-overview"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.hotel_costs')}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="sa-hotel-cost"
                    >
                        <NumberInputField
                            className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                            placeholder={translateTab('input_placeholder.please_enter_here')}
                            value={hotelCost}
                            onChange={value => onChangeHandler('hotelCost', value)}
                            min={0}
                            errors={hasCostError
                                ? [translateTab('error_message.please_enter_costs')]
                                : []
                            }
                        />
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.maximum_budget')}
                        qaIdent="sa-total-coverage"
                    >
                        {getPrice(serviceAssignment.totalCoverage, activeLocale)}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.difference')}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="sa-cost-difference"
                    >
                        {hotelCost && parseFloat(hotelCost) ? (
                            // show pill only when agent enters hotelCost
                            <Pill
                                type={isFullyCovered ? 'positive' : 'negative'}
                                className={cx('global!ace-u-typography--variant-body-medium')}
                            >
                                {isFullyCovered
                                    ? translateTab('difference_label.is_fully_covered')
                                    : `${getPrice(hotelCost - serviceAssignment.totalCoverage, activeLocale)} ${translateTab('difference_label.add_payment')}`}
                            </Pill>
                        ) : '-'}
                    </DataRow>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--justify-space-between',
                    ])}
                >
                    <DataRow
                        label={translateTab('data_row_label.member_phone_no')}
                        qaIdent="sa-member-contact-details-phone"
                    >
                        {serviceCase.member.contactDetails.phoneNo || '-'}
                    </DataRow>
                    {!isFullyCovered && (
                        <Checkbox
                            name="costConfirmedByMember"
                            value={true}
                            isSelected={!!costConfirmedByMember}
                            onChange={value => onChangeHandler('costConfirmedByMember', !!value)}
                            className={cx('global!ace-u-margin--bottom-16')}
                        >
                            {translateTab('checkbox_label.confirmed_by_member')}
                        </Checkbox>
                    )}
                </div>
            </div>
        </Panel>
    );
};

CostCoverageCostOverviewPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
    hotelCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // prop named differently to match DTO name
    // eslint-disable-next-line react/boolean-prop-naming
    costConfirmedByMember: PropTypes.bool,
    onChangeHandler: PropTypes.func,
    hasCostError: PropTypes.bool,
};

CostCoverageCostOverviewPanel.defaultProps = {
    serviceAssignment: {},
    serviceCase: {},
    hotelCost: '',
    costConfirmedByMember: false,
    onChangeHandler: null,
    hasCostError: false,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageCostOverviewPanel));
