import {take, fork, select, put} from 'redux-saga/effects';
import moment from 'moment';
import {mapMemberTariffGroupsFromAMP, apsBudgetTypes, efServiceAssignmentTypes, apsServiceTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import config from '../../config';

const loadBudgetMaxDurations = function* loadBudgetMaxDurations({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;

    if (!serviceCaseId || !serviceAssignmentLineNo) return;

    const {serviceCases} = yield select(state => state.serviceCases);
    const {serviceAssignments} = yield select(state => state.serviceAssignments);

    const serviceCase = serviceCases[serviceCaseId];
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];

    if (!serviceCase || !serviceAssignment) return;

    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');

    const {assignmentType} = serviceAssignment;
    const serviceType = assignmentType !== efServiceAssignmentTypes.RENTAL_CAR
        ? assignmentType
        : serviceCase.damage.location.countryCode === config.DAMAGE_LOCATION_GERMANY_COUNTRY_CODE
            ? apsServiceTypes.RENTAL_CAR__DOMESTIC_DAILY
            : null;

    if (!serviceType) return;

    const {member, createdAt} = serviceCase;
    const tariffGroup = member.tariffDetails.tariffGroup;

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('validOn', moment(createdAt).format('YYYY-MM-DD'));
    searchQueryParams.append('serviceType', serviceType);
    searchQueryParams.append('tariffGroup', mapMemberTariffGroupsFromAMP(tariffGroup));
    searchQueryParams.append('type', apsBudgetTypes.MAX_DURATIONS);

    yield fork(
        fetchRequest,
        serviceAssignmentActionTypes.FETCH_BUDGET_MAX_DURATIONS_REQUEST,
        pricingManagementService.getBudgets, {
            searchQueryParams,
        },
    );

    const responseAction = yield take([
        serviceAssignmentActionTypes.FETCH_BUDGET_MAX_DURATIONS_REQUEST_SUCCEEDED,
        serviceAssignmentActionTypes.FETCH_BUDGET_MAX_DURATIONS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {budgetDTOs} = response;

        yield put({
            type: serviceAssignmentActionTypes.STORE_BUDGET_MAX_DURATIONS,
            payload: {budgetDTOs},
        });
    }
};

export default loadBudgetMaxDurations;
