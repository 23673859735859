import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, DataRow, TextArea, InputCurrency} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import getPrice from '../../utils/getPrice';
import config from '../../config';

const CommissioningServiceAndDamageDescription = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const {serviceCase, assignmentText, onChangeHandler, currentAssignmentTextRef, serviceAssignment} = props;
    const {totalCost} = props;
    const translateTab = createTranslateShorthand('sav_commissioning_tab');
    const damageDescriptionTextRows = serviceCase.damage && serviceCase.damage.description
        ? serviceCase.damage.description.split('\n') : [];
    const additionalDescriptionTextRows = serviceCase.damage?.additionalDescription
        ? serviceCase.damage.additionalDescription.split('\n') : [];

    return (
        <Panel
            className={cx('global!ace-u-flex--grow-1')}
            title={(
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--grow-1',
                        'global!ace-u-flex--justify-space-between',
                    ])}
                >
                    <p>{translateTab('panel_title.service_and_damage_description')}</p>
                </div>
              )}
            qaIdent="sav-commissioning-service-and-damage-description"
        >
            <DataRow
                label={translateTab('data_row_label.conditions')}
                className={cx('ace-c-data-row--condensed')}
                qaIdent="sa-assignment-text"
            >
                <TextArea
                    className={cx('global!ace-u-flex--grow-1')}
                    isResizable={false}
                    value={assignmentText}
                    onChange={value => onChangeHandler('assignmentText', value)}
                    ref={currentAssignmentTextRef}
                />
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.damage_description')}
                qaIdent="sa-damage-description"
            >
                <div>
                    {damageDescriptionTextRows.map((stringRow, idx) => (
                        <p key={`${stringRow}-${idx}`}>
                            {stringRow}
                        </p>
                    ))}
                    <br />
                    {serviceCase.damageDescription}
                </div>
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.please_note')}
                qaIdent="sa-additional-description"
            >
                <div>
                    {additionalDescriptionTextRows.map((stringRow, idx) => {
                        if (stringRow) {
                            return (
                                <p key={`${stringRow}-${idx}`}>
                                    {stringRow}
                                </p>
                            );
                        }
                        return <br key={`${stringRow}-${idx}`} />;
                    })}
                    <br />
                </div>
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.costs')}
                qaIdent="sa-ace-partner-costs"
            >
                {!(serviceAssignment?.assignmentType === efServiceAssignmentTypes.VEHICLE
                    && serviceAssignment?.serviceCase?.prefix === config.ACE_COMMISSIONER_ID.toString())
                    ? getPrice(serviceAssignment?.totalCost || '', activeLocale)
                    : (
                        <InputCurrency
                            name="totalCost"
                            value={totalCost || ''}
                            onChange={value => onChangeHandler('totalCost', value)}
                        />
                    )
                }
            </DataRow>
        </Panel>
    );
};

CommissioningServiceAndDamageDescription.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    assignmentText: PropTypes.string,
    onChangeHandler: PropTypes.func,
    currentAssignmentTextRef: PropTypes.object,
    totalCost: PropTypes.string,
};

CommissioningServiceAndDamageDescription.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    assignmentText: '',
    onChangeHandler: null,
    currentAssignmentTextRef: null,
    totalCost: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CommissioningServiceAndDamageDescription));
