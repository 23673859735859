import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, TextArea, NumberInput} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const SASPCommissioningAgreedPricePanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sasp_commissioning_tab');
    const {onChangeHandler, assignmentText, totalCoverage, serviceAssignment} = props;

    return (
        <Panel
            className={cx('global!ace-u-flex--grow-1')}
            title={(
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--grow-1',
                        'global!ace-u-flex--justify-space-between',
                    ])}
                >
                    <p>{translateTab('panel_title.commissioning')}</p>
                </div>
            )}
            qaIdent="sasp-commissioning-agreed-price"
        >
            <DataRow
                label={translateTab('data_row_label.description')}
                className={cx('ace-c-data-row--condensed')}
                qaIdent="sa-agreed-price-description"
            >
                <TextArea
                    className={cx('global!ace-u-flex--grow-1')}
                    isResizable={false}
                    value={assignmentText}
                    onChange={value => onChangeHandler('assignmentText', value)}
                />
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.agreed_price')}
                className={cx('ace-c-data-row--condensed')}
                qaIdent="sa-agreed-price"
            >
                <NumberInput
                    className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                    value={totalCoverage}
                    onChange={value => onChangeHandler('totalCoverage', value)}
                    min={0}
                />
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.pickup_date')}
                qaIdent="sa-pick-up-date"
            >
                {serviceAssignment?.pickupDate ? moment(serviceAssignment.pickupDate).format('DD.MM.YYYY') : ''}
            </DataRow>
        </Panel>
    );
};

SASPCommissioningAgreedPricePanel.propTypes = {
    assignmentText: PropTypes.string,
    totalCoverage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChangeHandler: PropTypes.func.isRequired,
    serviceAssignment: PropTypes.object,
};

SASPCommissioningAgreedPricePanel.defaultProps = {
    assignmentText: '',
    totalCoverage: '',
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(SASPCommissioningAgreedPricePanel));
