import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, efServiceCaseTypes, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {useArcGISMap, createMarkerGraphic, markerVehiclePNG, markerWorkshopPNG, mapArcGISPOITypeToMarkerIcon, markerTowingActivePNG} from '@ace-de/eua-arcgis-map';
import {useStyles, ContentBlock, ContentItem, Panel, ScrollableBlock, RadioButtonGroup, SearchBox, NoResultsBlock, ToggleSwitch, LoaderSpinner} from '@ace-de/ui-components';
import {Icon, findCaseIcon} from '@ace-de/ui-components/icons';
import * as savaActionTypes from './savaActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import ContractPartnerItem from '../service-assignments-vehicle/ui-elements/ContractPartnerItem';
import serviceAssignmentPartnerDisabledStateStatuses from '../service-assignments/serviceAssignmentPartnerDisabledStateStatuses';
import config from '../config';
import routePaths from '../routePaths';
import savaScreenTabs from './savaScreenTabs';

const SAVAContractPartnerTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sava_contract_partner_tab');
    const arcGISMap = useArcGISMap('service-assignment-vehicle-additional');
    const {serviceCase, serviceAssignment, submitSAVAContractPartnerForm, searchSAVAContractPartners} = props;
    const {arcGISNearbyPOIs, setArcGISNearbyPOIs, initiateServiceAssignmentACEPartnerDeletionFlow} = props;
    const {initiateServiceAssignmentQMFeedbackDraftCreationFlow, history, contractPartners} = props;
    const {setActiveContractPartnerRecommendationsSnapshot, contractPartnerRecommendationsPersistenceState} = props;
    const [selectedContractPartnerId, setSelectedContractPartnerId] = useState('');
    const [contractPartnerSearchQuery, setContractPartnerSearchQuery] = useState('');
    const [isVBASearchActive, setIsVBASearchActive] = useState(serviceAssignment?.acePartner?.id && contractPartners
        /* eslint-disable-next-line max-len */
        && contractPartners[serviceAssignment.acePartner.id]?.contractStatus === apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY);

    useEffect(() => {
        const {acePartner: currentContractPartner} = serviceAssignment;
        if (!currentContractPartner || selectedContractPartnerId === currentContractPartner.id) return;

        setSelectedContractPartnerId(currentContractPartner.id);
    }, [serviceAssignment, selectedContractPartnerId]);

    useEffect(() => {
        if (!arcGISMap || !serviceCase || !serviceAssignment) return;

        arcGISMap.hideLayer('sava-towing-destinations');
        arcGISMap.hideLayer('sava-towing-destination-routes');

        const {serviceLocation} = serviceAssignment;
        arcGISMap.setGraphics({
            graphics: [
                ...(serviceLocation && serviceLocation.latitude && serviceLocation.longitude ? [
                    createMarkerGraphic({
                        id: 'serviceLocation',
                        longitude: serviceLocation.longitude,
                        latitude: serviceLocation.latitude,
                        icon: markerVehiclePNG,
                    }),
                ] : []),
                ...(serviceAssignment.towingDestination ? [
                    createMarkerGraphic({
                        id: 'towingDestination',
                        longitude: serviceAssignment.towingDestination.longitude,
                        latitude: serviceAssignment.towingDestination.latitude,
                        icon: markerWorkshopPNG,
                    }),
                ] : []),
                ...(serviceAssignment.acePartner?.isManuallyAdded ? [
                    createMarkerGraphic({
                        id: 'manuallyAddedACEPartner',
                        longitude: serviceAssignment.acePartner.location?.longitude,
                        latitude: serviceAssignment.acePartner.location?.latitude,
                        icon: markerTowingActivePNG,
                    }),
                ] : []),
                ...(serviceAssignment.acePartner?.isManuallyAdded
                    && serviceAssignment.acePartner?.routeToDamageLocation?.geometry
                    ? [
                        createMarkerGraphic({
                            id: 'manuallyAddedACEPartnerRoute',
                            geometry: serviceAssignment.acePartner.routeToDamageLocation.geometry,
                            isRouteSymbol: true,
                            isActive: true,
                        }),
                    ] : []),
                ...(arcGISNearbyPOIs?.length
                    ? arcGISNearbyPOIs.map(poi => createMarkerGraphic({
                        id: poi.id,
                        longitude: poi.longitude,
                        latitude: poi.latitude,
                        icon: mapArcGISPOITypeToMarkerIcon(Array.isArray(poi.establishmentType)
                            ? poi.establishmentType[0] : ''),
                        isSmall: true,
                        popupFields: {
                            formattedAddress: poi.formattedAddress,
                        },
                        popupTemplate: {
                            title: poi.locationName,
                            content: `<p>{formattedAddress}</p>`,
                        },
                    })) : []),
            ],
        });
    }, [arcGISMap, serviceCase, serviceAssignment, arcGISNearbyPOIs]);

    const handleDeleteManuallyAddedContractPartner = () => {
        initiateServiceAssignmentACEPartnerDeletionFlow({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                // remove manually added ACE partner and select the recommended one if exist
                acePartner: recommendedContractPartner || null,
            },
            assignmentType: serviceAssignment.assignmentType,
            isPartnerChangingAllowed: !(caseType === efServiceCaseTypes.VEHICLE
                && serviceAssignmentPartnerDisabledStateStatuses.includes(serviceAssignment.assignmentStatus)),
        });
    };

    const handleContractPartnerSelect = newContractPartnerId => {
        if (!newContractPartnerId || selectedContractPartnerId === newContractPartnerId) return;

        setSelectedContractPartnerId(newContractPartnerId);
        const {contractPartnerRecommendations} = serviceAssignment;
        const selectedPartner = contractPartnerRecommendations
            .find(contractPartner => contractPartner.id === newContractPartnerId);
        const selectedACEPartner = contractPartners[newContractPartnerId] || null;

        /* in order to get correct information on the CP panel
        * we have to patch assignmentEmail and assignmentFaxNo
        * to contact details and replace current email and faxNo, since CP panel operates with those values
        * */
        submitSAVAContractPartnerForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                acePartner: {
                    ...selectedPartner,
                    ...(selectedPartner.businessContactDetails
                        ? {
                            businessContactDetails: {
                                ...selectedPartner.businessContactDetails,
                                email: selectedACEPartner?.assignmentEmail || '',
                                faxNo: selectedACEPartner?.assignmentFaxNo || '',
                                phoneNo: selectedACEPartner?.emergencyContacts?.find(contact => contact.is24h7Emergency)?.phoneNo || '',
                            },
                            contactDetails: null,
                        } : {}
                    ),
                },
            },
        });
    };

    const handleSearchQueryChange = value => {
        if (!value) {
            searchSAVAContractPartners({
                serviceCaseId: serviceAssignment.serviceCaseId,
                serviceAssignmentLineNo: serviceAssignment.lineNo,
                searchQuery: null,
                isVBASearch: isVBASearchActive,
            });
        }
        setContractPartnerSearchQuery(value);
    };

    const handleContractPartnerSearch = () => {
        if (contractPartnerSearchQuery.length > config.MINIMUM_CP_SEARCH_QUERY_LENGTH) {
            searchSAVAContractPartners({
                serviceCaseId: serviceAssignment.serviceCaseId,
                serviceAssignmentLineNo: serviceAssignment.lineNo,
                searchQuery: contractPartnerSearchQuery,
                isVBASearch: isVBASearchActive,
            });
        }
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        // on tab change, always reset serviceAssignment.arcGISNearbyPOIs
        setArcGISNearbyPOIs({
            arcGISLocationCandidateDTOs: [],
            selectedPOIsCategory: '',
        });

        if (serviceAssignment?.acePartner?.isManuallyAdded) {
            completeRouteUnmountSideEffect({
                caller: serviceAssignmentActionTypes.SET_ARCGIS_NEARBY_POIS,
            });
            return;
        }

        // do not create a draft placeholder when an agent switches to a VBA search,
        // since we are not creating generic QM notes when the VBA partner rejects
        if (isVBASearchActive) {
            initiateServiceAssignmentQMFeedbackDraftCreationFlow({
                isDraftPlaceholderDeletion: true,
                qmFeedbackDraftData: {
                    caseId: serviceAssignment.serviceCaseId,
                    lineNo: serviceAssignment.lineNo,
                },
            });
            completeRouteUnmountSideEffect({
                caller: serviceAssignmentActionTypes.SET_ARCGIS_NEARBY_POIS,
            });
            return;
        }

        // on tab change, if no qm draft is created, create new qm draft which will act as qm placeholder
        // and will be deleted on status accepted
        initiateServiceAssignmentQMFeedbackDraftCreationFlow({
            qmFeedbackDraftData: {
                contractPartnerId: selectedContractPartnerId || serviceAssignment.recommendedContractPartnerId,
                caseId: serviceAssignment.serviceCaseId,
                lineNo: serviceAssignment.lineNo,
                contractPartnerRecommendations: serviceAssignment.contractPartnerRecommendations
                    .map(contractPartner => ({
                        id: contractPartner.id,
                        rank: contractPartner.rank,
                        routeToDamageLocation: {
                            totalKilometers: contractPartner.routeToDamageLocation?.totalKilometers,
                            totalTravelTime: contractPartner.routeToDamageLocation?.totalTravelTime,
                        },
                        formattedAddress: contractPartner.location?.formattedAddress,
                    })),
            },
        });

        completeRouteUnmountSideEffect({
            caller: serviceAssignmentActionTypes.SET_ARCGIS_NEARBY_POIS,
        });
    }, [
        setArcGISNearbyPOIs,
        initiateServiceAssignmentQMFeedbackDraftCreationFlow,
        serviceAssignment,
        selectedContractPartnerId,
        contractPartners,
        isVBASearchActive,
    ]);

    const handleVBAContractPartnerSearch = value => {
        setIsVBASearchActive(!!value);
        // remove the existing query parameters and apply new
        const queryParams = new URLSearchParams();
        queryParams.delete('activeTab');
        queryParams.append('activeTab', savaScreenTabs.CONTRACT_PARTNER);
        queryParams.delete('isVBASearch');
        queryParams.append('isVBASearch', !!value);
        // when switching from the VBA search to a default one, delete QM draft if exists
        if (!value) {
            initiateServiceAssignmentQMFeedbackDraftCreationFlow({
                isDraftDeletion: true,
                qmFeedbackDraftData: {
                    caseId: serviceAssignment.serviceCaseId,
                    lineNo: serviceAssignment.lineNo,
                },
            });
        }
        // set/reset activeContractPartnerRecommendationsSnapshot data
        setActiveContractPartnerRecommendationsSnapshot({
            contractPartnerRecommendations: value
                ? serviceAssignment.contractPartnerRecommendations : [],
            serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
        });
        history.replace(resolveRoute(
            routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL,
            {serviceCaseId: serviceCase.id, serviceAssignmentLineNo: serviceAssignment.lineNo},
            {search: queryParams.toString()},
        ));
    };

    // if no case or assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    const {contractPartnerRecommendations, recommendedContractPartnerId, assignmentStatus} = serviceAssignment;
    const {listOfPreviousPartners} = serviceAssignment;
    const {caseType} = serviceCase;
    const recommendedContractPartner = contractPartnerRecommendations
        .find(contractPartner => contractPartner.id === recommendedContractPartnerId);
    const isPartnerChangingDisabled = !!serviceAssignment?.acePartner?.isManuallyAdded
        || serviceAssignmentPartnerDisabledStateStatuses.includes(assignmentStatus);
    const isVBASearchParam = (new URLSearchParams(history?.location?.search || '')).get('isVBASearch');
    const isSearchInProgress = contractPartnerRecommendationsPersistenceState === persistenceStates.PENDING;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'ace-c-content-block--sidebar',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-content-item--sidebar',
                ])}
            >
                <Panel
                    title={translateTab('panel_title.select_contract_partner')}
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <ScrollableBlock isLabelDisabled={true}>
                        <div className={cx('global!ace-u-flex')}>
                            <SearchBox
                                isComposedIn={true}
                                name="searchContractPartner"
                                placeholder={translateTab('search_box_placeholder.contract_partner_search')}
                                className={cx([
                                    'global!ace-u-flex--grow-1',
                                    'global!ace-u-margin--16-0',
                                ])}
                                value={contractPartnerSearchQuery}
                                onChange={handleSearchQueryChange}
                                onSearchSubmit={handleContractPartnerSearch}
                                isDropDownDisabled={true}
                                isDisabled={serviceCase.persistenceState === persistenceStates.PENDING
                                || serviceAssignment.persistenceState === persistenceStates.PENDING}
                            />
                        </div>
                        {!isSearchInProgress && !!contractPartnerRecommendations.length && (
                            <RadioButtonGroup
                                name="selectedContractPartnerId"
                                value={selectedContractPartnerId}
                                onChange={handleContractPartnerSelect}
                                isDisabled={serviceCase.persistenceState === persistenceStates.PENDING
                                    || serviceAssignment.persistenceState === persistenceStates.PENDING}
                            >
                                <h4
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-typography--align-left',
                                        'global!ace-u-margin--bottom-24',
                                    ])}
                                >
                                    {translateTab('heading.nearest_contract_partner')}
                                </h4>
                                {recommendedContractPartner ? (
                                    <ContractPartnerItem
                                        key={recommendedContractPartner.id}
                                        contractPartner={recommendedContractPartner}
                                        isDisabled={isPartnerChangingDisabled
                                        || listOfPreviousPartners?.includes(recommendedContractPartner.id)
                                        }
                                    />
                                ) : (
                                    <div
                                        className={cx([
                                            'global!ace-u-typography--align-left',
                                            'global!ace-u-margin--bottom-24',
                                        ])}
                                    >
                                        {translateTab('text.nearest_contract_partner_unavailable')}
                                    </div>
                                )}
                                {!!serviceAssignment?.acePartner?.isManuallyAdded && (
                                    <h4
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-typography--align-left',
                                            'global!ace-u-margin--bottom-24',
                                        ])}
                                    >
                                        {translateTab('heading.selected_partner')}
                                    </h4>
                                )}
                                {!!serviceAssignment?.acePartner?.isManuallyAdded && (
                                    <ContractPartnerItem
                                        // TODO: check if we need an ID
                                        key={serviceAssignment.acePartner.location.id}
                                        contractPartner={serviceAssignment.acePartner}
                                        isManuallyAdded={true}
                                        assignmentStatus={serviceAssignment.assignmentStatus}
                                        handleOnDelete={handleDeleteManuallyAddedContractPartner}
                                    />
                                )}
                                <h4
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-typography--align-left',
                                        'global!ace-u-margin--bottom-24',
                                    ])}
                                >
                                    {translateTab('heading.other_contract_partners')}
                                </h4>
                                {contractPartnerRecommendations.map(contractPartner => {
                                    if (recommendedContractPartner
                                        && contractPartner.id === recommendedContractPartner.id) return null;

                                    return (
                                        <ContractPartnerItem
                                            key={contractPartner.id}
                                            contractPartner={contractPartner}
                                            isDisabled={isPartnerChangingDisabled
                                            || listOfPreviousPartners?.includes(contractPartner.id)
                                            }
                                        />
                                    );
                                })}
                            </RadioButtonGroup>
                        )}
                        {!isSearchInProgress && !contractPartnerRecommendations.length && (
                            <Fragment>
                                <h4
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-typography--align-left',
                                        'global!ace-u-margin--bottom-16',
                                    ])}
                                >
                                    {translateTab('heading.nearest_contract_partner')}
                                </h4>
                                <NoResultsBlock
                                    icon={(
                                        <Icon
                                            className={cx('ace-c-icon--xxl')}
                                            icon={findCaseIcon}
                                        />
                                    )}
                                    variant="medium"
                                    message={translateTab('cp_no_results.message')}
                                    className={cx('global!ace-u-typography--align-center')}
                                    description={translateTab('cp_no_results.description')}
                                />
                            </Fragment>
                        )}
                        {isSearchInProgress && (
                            <div
                                className={cx([
                                    'global!ace-u-padding--top-32',
                                    'global!ace-u-width--full',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-center',
                                ])}
                            >
                                <LoaderSpinner />
                            </div>
                        )}
                        {!isSearchInProgress && (
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-row-reverse',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-typography--variant-body-bold',
                                ])}
                            >
                                <ToggleSwitch
                                    name="toggleVBAContractPartnerSearch"
                                    value={true}
                                    isSelected={isVBASearchParam === 'false'
                                        ? null : isVBASearchActive}
                                    onChange={handleVBAContractPartnerSearch}
                                    isDisabled={!!serviceAssignment?.acePartner?.isManuallyAdded}
                                />
                                <p className={cx('global!ace-u-margin--right-8')}>
                                    {translateTab('toggle_label.show_vba_companies')}
                                </p>
                            </div>
                        )}
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
        </ContentBlock>
    );
};

SAVAContractPartnerTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    submitSAVAContractPartnerForm: PropTypes.func.isRequired,
    searchSAVAContractPartners: PropTypes.func.isRequired,
    arcGISNearbyPOIs: PropTypes.array,
    setArcGISNearbyPOIs: PropTypes.func.isRequired,
    initiateServiceAssignmentACEPartnerDeletionFlow: PropTypes.func.isRequired,
    initiateServiceAssignmentQMFeedbackDraftCreationFlow: PropTypes.func.isRequired,
    setActiveContractPartnerRecommendationsSnapshot: PropTypes.func.isRequired,
    history: PropTypes.object,
    contractPartners: PropTypes.object,
    contractPartnerRecommendationsPersistenceState: PropTypes.string.isRequired,
};

SAVAContractPartnerTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    arcGISNearbyPOIs: [],
    history: null,
    contractPartners: null,
};

const mapStateToProps = (state, props) => {
    return {
        arcGISNearbyPOIs: state.serviceAssignments.arcGISNearbyPOIs,
        contractPartners: state.contractPartners.contractPartners,
        contractPartnerRecommendationsPersistenceState: state.serviceAssignments.contractPartnerRecommendationsPersistenceState, // eslint-disable-line max-len
    };
};

const mapDispatchToProps = dispatch => ({
    submitSAVAContractPartnerForm: payload => dispatch({
        type: savaActionTypes.SUBMIT_SAVA_CONTRACT_PARTNER_FORM,
        payload,
    }),
    searchSAVAContractPartners: payload => dispatch({
        type: savaActionTypes.SEARCH_SAVA_CONTRACT_PARTNERS,
        payload,
    }),
    setArcGISNearbyPOIs: payload => dispatch({
        type: serviceAssignmentActionTypes.SET_ARCGIS_NEARBY_POIS,
        payload,
    }),
    initiateServiceAssignmentACEPartnerDeletionFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_ACE_PARTNER_DELETION_FLOW,
        payload,
    }),
    initiateServiceAssignmentQMFeedbackDraftCreationFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_QM_FEEDBACK_DRAFT_CREATION_FLOW,
        payload,
    }),
    setActiveContractPartnerRecommendationsSnapshot: payload => dispatch({
        type: savaActionTypes.SET_SAVA_ACTIVE_CONTRACT_PARTNER_RECOMMENDATIONS_SNAPSHOT,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAVAContractPartnerTab));
