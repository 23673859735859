import React, {useState, useCallback, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {apsServiceTypes, efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Form, NumberInputField, ToggleSwitch} from '@ace-de/ui-components';
import * as sastActionTypes from '../sastActionTypes';
import getPrice from '../../utils/getPrice';

const SASTBudgetPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sast_requirements_tab');
    const {serviceAssignment, submitSASTBudgetForm, serviceCase, serviceAssignmentBudgets} = props;
    const [budgetData, setBudgetData] = useState(serviceAssignment);

    const handleOnTabChange = useCallback(formValues => {
        const {overrideDefaultBudget, budgetPerTrip} = formValues;
        const serviceAssignmentData = {
            overrideDefaultBudget,
            // if an assignment is assigned, do NOT change budgetTrip and totalCoverage info
            // so an agent can see the valid remaining budget at the time when he/she was filling
            // this short trip assignment
            ...(serviceAssignment.assignmentStatus !== efServiceAssignmentStatusTypes.ASSIGNED && {
                budgetPerTrip: overrideDefaultBudget
                    ? budgetPerTrip
                    : (typeof serviceCase.remainingBudget === 'number'
                        ? serviceCase.remainingBudget : serviceAssignmentBudgets?.[apsServiceTypes.SHORT_TRIP]?.value),
            }),
            ...(serviceAssignment.assignmentStatus !== efServiceAssignmentStatusTypes.ASSIGNED && {
                totalCoverage: overrideDefaultBudget
                    ? budgetPerTrip
                    : (typeof serviceCase.remainingBudget === 'number'
                        ? serviceCase.remainingBudget : serviceAssignmentBudgets?.[apsServiceTypes.SHORT_TRIP]?.value),
            }),
            // mandatory
            serviceCaseId: serviceAssignment.serviceCaseId,
        };

        submitSASTBudgetForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData,
        });
    }, [
        submitSASTBudgetForm,
        serviceAssignment,
        serviceCase.remainingBudget,
        serviceAssignmentBudgets,
    ]);

    const handleOnChange = formValues => {
        setBudgetData({
            ...formValues,
            overrideDefaultBudget: !!formValues.overrideDefaultBudget,
        });
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!budgetData) {
            completeRouteUnmountSideEffect({
                caller: sastActionTypes.SUBMIT_SAST_BUDGET_FORM,
            });
            return;
        }

        handleOnTabChange(budgetData);
    }, [budgetData, handleOnTabChange]);

    return (
        <Panel title={translateTab('budget_panel_title.budget')}>
            <Form name="sastBudgetForm" onChange={handleOnChange}>
                {formValues => {
                    return (
                        <Fragment>
                            <NumberInputField
                                name="budgetPerTrip"
                                label={translateTab('budget_panel_input_label.budget_per_trip')}
                                value={budgetData.overrideDefaultBudget
                                    ? budgetData.budgetPerTrip
                                    : (serviceAssignment.assignmentStatus !== efServiceAssignmentStatusTypes.ASSIGNED
                                        ? (typeof serviceCase.remainingBudget === 'number'
                                            ? serviceCase.remainingBudget : serviceAssignmentBudgets?.[apsServiceTypes.SHORT_TRIP]?.value || '')
                                        : serviceAssignment.totalCoverage)}
                                isDisabled={!budgetData.overrideDefaultBudget
                                    || serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.ASSIGNED}
                                className={cx(['global!ace-u-full-width', 'global!ace-u-padding--bottom-32'])}
                                min={0}
                            />
                            <ToggleSwitch
                                name="overrideDefaultBudget"
                                value={true}
                                isSelected={!!budgetData.overrideDefaultBudget}
                                className={cx('global!ace-u-padding--bottom-24')}
                                isDisabled={serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.ASSIGNED} // eslint-disable-line max-len
                            >
                                {translateTab('budget_panel_toggle_switch_label.adjust_budget')}
                            </ToggleSwitch>
                            <div className={cx(['global!ace-u-padding--top-24', 'global!ace-u-padding--bottom-8'])}>
                                {translateTab('budget_panel_text.cost_coverage_sum')}
                            </div>
                            <div className={cx(['global!ace-u-typography--variant-h2', 'global!ace-u-margin--bottom-24'])}>
                                {getPrice(parseFloat(formValues.budgetPerTrip || 0), activeLocale)}
                            </div>
                        </Fragment>
                    );
                }}
            </Form>
        </Panel>
    );
};

SASTBudgetPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
    serviceAssignmentBudgets: PropTypes.object,
    submitSASTBudgetForm: PropTypes.func.isRequired,
};

SASTBudgetPanel.defaultProps = {
    serviceAssignment: {},
    serviceCase: {},
    serviceAssignmentBudgets: null,
};

const mapStateToProps = state => ({
    serviceAssignmentBudgets: state.serviceAssignments.serviceAssignmentBudgets,
});

const mapDispatchToProps = dispatch => ({
    submitSASTBudgetForm: payload => dispatch({
        type: sastActionTypes.SUBMIT_SAST_BUDGET_FORM,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SASTBudgetPanel);
