import {take, fork, put, select} from 'redux-saga/effects';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as sapActionTypes from '../sapActionTypes';

/**
 * Calculate the route distance: pickup location → destination + destination → CP
 */
const calculatePickupRouteDistance = function* calculatePickupRouteDistance() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    let pickupLocationToDestinationDistance = 0;
    let destinationToContractPartnerDistance = 0;

    while (true) {
        const {payload} = yield take(sapActionTypes.CALCULATE_PICKUP_ROUTE_DISTANCE);
        const {serviceAssignmentId, destination, pickupLocation, contractPartner} = payload;

        if (!pickupLocation || !destination || !contractPartner) continue;

        // calculate the distance between pickup location and destination
        if (pickupLocation && destination) {
            yield fork(
                fetchRequest,
                sapActionTypes.FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: destination.longitude,
                        latitude: destination.latitude,
                    },
                    destination: {
                        longitude: pickupLocation.longitude,
                        latitude: pickupLocation.latitude,
                    },
                    travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
                },
            );

            const routeResponseAction = yield take([
                sapActionTypes.FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SUCCEEDED,
                sapActionTypes.FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_FAILED,
            ]);

            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;
                pickupLocationToDestinationDistance = arcGISRouteDTO.totalKilometers;
            }
        }

        // calculate the distance between destination and CP
        if (destination && contractPartner?.location) {
            yield fork(
                fetchRequest,
                sapActionTypes.FETCH_DESTINATION_TO_CONTRACT_PARTNER_DISTANCE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: destination.longitude,
                        latitude: destination.latitude,
                    },
                    destination: {
                        longitude: contractPartner.location.longitude,
                        latitude: contractPartner.location.latitude,
                    },
                    travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
                },
            );

            const routeResponseAction = yield take([
                sapActionTypes.FETCH_DESTINATION_TO_CONTRACT_PARTNER_DISTANCE_REQUEST_SUCCEEDED,
                sapActionTypes.FETCH_DESTINATION_TO_CONTRACT_PARTNER_DISTANCE_REQUEST_FAILED,
            ]);

            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;
                destinationToContractPartnerDistance = arcGISRouteDTO.totalKilometers;
            }
        }

        const routeDistance = pickupLocationToDestinationDistance + destinationToContractPartnerDistance;

        yield put({
            type: sapActionTypes.SET_SAP_PICKUP_ROUTE_DISTANCE,
            payload: {routeDistance, serviceAssignmentId},
        });
    }
};

export default calculatePickupRouteDistance;
