import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import getPrice from '../../utils/getPrice';

const RemainingBudgetPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sc_service_assignments_tab');
    const {serviceCase, serviceCaseShortTripBudget} = props;

    // if no service case, don't render
    if (!serviceCase) return null;

    return (
        <Panel title={translateTab('remaining_budget_panel.title')}>
            <p className={cx('global!ace-u-typography--variant-body')}>
                {translateTab('remaining_budget_panel.heading')}
            </p>
            <div className={cx(['global!ace-u-typography--variant-h2', 'global!ace-u-margin--top-16'])}>
                {getPrice(typeof serviceCase.remainingBudget === 'number'
                    ? serviceCase.remainingBudget : serviceCaseShortTripBudget?.value, activeLocale)}
            </div>
        </Panel>
    );
};

RemainingBudgetPanel.propTypes = {
    serviceCase: PropTypes.object,
    serviceCaseShortTripBudget: PropTypes.object,
};

RemainingBudgetPanel.defaultProps = {
    serviceCase: null,
    serviceCaseShortTripBudget: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceCaseShortTripBudget: state.serviceCases.serviceCaseShortTripBudget,
    };
};

export default withRouter(connect(mapStateToProps, null)(RemainingBudgetPanel));
